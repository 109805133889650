<template>
  <vue-final-modal v-model="showModal" name="track-details-modal">
    <div :class="$style.container">
      <div :class="$style.content">
        <button
          :class="$style.closeModalButton"
          @click.prevent="showModal = false"
        >
          <BaseIcon icon="times" />
        </button>
        <div v-if="fetchTrackDetailsStatusPending">
          <p class="$style.loadingDetails">
            <BaseSpinner show />
          </p>
        </div>

        <div v-else-if="fetchTrackDetailsStatusError">
          <p>There was a problem while fetching data</p>
        </div>
        <div v-else-if="fetchTrackDetailsStatusSuccess && track">
          <div :class="$style.header">
            <h2>{{ track.title }}</h2>
            <div
              v-if="track.confidence"
              :class="[
                $style.confidence,
                getConfidenceAlertStyle(track.confidence),
              ]"
            >
              {{ track.confidence }}% confidence
            </div>
          </div>
          <ul :class="$style.songDetailsList">
            <li>
              <span :class="$style.highlight">ISRC:</span>
              <span>{{ track.isrc || "N/A" }}</span>
            </li>
            <li>
              <span :class="$style.highlight">HFA Code:</span>
              <span>{{ track.hfa_code || "N/A" }}</span>
            </li>
            <li>
              <span :class="$style.highlight">ISWC:</span>
              <span>{{ track.iswc || "N/A" }}</span>
            </li>
            <li>
              <span :class="$style.highlight">Performers:</span>
              <span>{{ track.performers || "N/A" }}</span>
            </li>
          </ul>

          <div :class="$style.detailsSectionContainer">
            <div :class="$style.detailSection">
              <span :class="[$style.highlight, $style.sectionTitle]"
                >Writers ({{ track.writers.length }})</span
              >
              <div :class="$style.sectionGridContainer">
                <div :class="$style.sectionGrid">
                  <span :class="$style.columnHeadline">Name</span>
                  <span :class="$style.columnHeadline">Artist Name</span>
                  <span :class="$style.columnHeadline">Role</span>
                  <span :class="$style.columnHeadline">Society</span>
                  <span :class="$style.columnHeadline">Ipi</span>
                </div>
                <div
                  :class="$style.sectionGrid"
                  v-for="(
                    {
                      alias,
                      name,
                      interested_party,
                      artist_name,
                      role,
                      society,
                      ipi,
                    },
                    idx
                  ) of track.writers"
                  :key="`${interested_party}-${idx}`"
                >
                  <span>{{ name || interested_party || "-" }}</span>
                  <span>{{ alias || artist_name || "-" }}</span>
                  <span>{{ role || "-" }}</span>
                  <span>{{ society || "-" }}</span>
                  <span>{{ ipi || "-" }}</span>
                </div>
              </div>
            </div>

            <div :class="$style.detailSection">
              <span :class="[$style.highlight, $style.sectionTitle]"
                >Publishers ({{ track.publishers.length }})</span
              >
              <div :class="$style.sectionGridContainer">
                <div :class="$style.sectionGrid">
                  <span :class="$style.columnHeadline">Name</span>
                  <span :class="$style.columnHeadline">Admin</span>
                  <span :class="$style.columnHeadline">Role</span>
                  <span :class="$style.columnHeadline">Society</span>
                  <span :class="$style.columnHeadline">Ipi</span>
                </div>
                <div
                  :class="$style.sectionGrid"
                  v-for="(
                    {
                      alias,
                      name,
                      interested_party,
                      party_admin,
                      role,
                      society,
                      ipi,
                    },
                    idx
                  ) of track.publishers"
                  :key="`${interested_party}-${idx}`"
                >
                  <span>{{ name || interested_party || "-" }}</span>
                  <span>{{ alias || party_admin || "-" }}</span>
                  <span>{{ role || "-" }}</span>
                  <span>{{ society || "-" }}</span>
                  <span>{{ ipi || "-" }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.actions">
        <div :class="$style.pagination">
          <div>
            <BaseButton
              :class="$style.paginationButton"
              :disabled="!this.prevTrack"
              @click.prevent="$emit('prev', prevTrack)"
              >Previous</BaseButton
            >
          </div>
          <div :class="$style.paginationDivider">|</div>
          <div>
            <BaseButton
              :class="$style.paginationButton"
              :disabled="!this.nextTrack"
              @click.prevent="$emit('next', nextTrack)"
              >Next</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { API_STATUS } from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";

export default {
  name: "TrackDetailsModal",
  props: {
    fetchTrackDetailsStatus: {
      type: String,
    },
    track: {
      type: Object,
    },
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    this.API_STATUS = API_STATUS;
  },
  computed: {
    ...apiStatusComputedFactory("fetchTrackDetailsStatus"),
    selectedTrackIdx() {
      return (
        this.track &&
        this.tracks.findIndex((_track) => _track.isrc === this.track?.isrc)
      );
    },
    prevTrack() {
      if (!Number.isInteger(this.selectedTrackIdx)) return null;
      if (this.selectedTrackIdx === 0) return null;
      else return this.tracks[this.selectedTrackIdx - 1];
    },
    nextTrack() {
      if (!Number.isInteger(this.selectedTrackIdx)) return null;
      if (this.selectedTrackIdx > this.tracks.length - 1) return null;
      else return this.tracks[this.selectedTrackIdx + 1];
    },
  },
  methods: {
    getConfidenceAlertStyle(confidence) {
      if (confidence >= 90) {
        return this.$style.greatConfidence;
      }

      if (confidence >= 80) {
        return this.$style.goodConfidence;
      }

      return this.$style.questionableConfidence;
    },
  },
};
</script>

 <style lang="scss" module>
$primary: #bd7eee;
.container {
  border-radius: 10px;
  background: white;
  min-height: 90vh;
  max-width: 90vw;
  width: 100%;
  position: relative;
  top: 5vh;
  left: 5vh;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}
.content {
  position: relative;
  padding: 4rem;
  flex-grow: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confidence {
  padding: 0.5rem;
}

.greatConfidence {
  background-color: #dcfce7;
  color: #15803d;
}
.goodConfidence {
  background-color: #ffedd5;
  color: #9a3412;
}
.questionableConfidence {
  background-color: #fee2e2;
  color: #991b1b;
}

.loadingDetails {
}

.closeModalButton {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.25rem;
  color: $primary;
}

.highlight {
  font-weight: 500;
  color: $primary;
}

.songDetailsList {
  margin-bottom: 3rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.25rem;
  padding: 0;
  li {
    line-height: 1.4;
    &:not(:last-child) {
      flex-shrink: 0;
    }
  }
  li span {
    margin-right: 0.75rem;
  }
}

.sectionTitle {
  display: block;
  margin-bottom: 0.5rem;
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 0.5rem;
  z-index: 5;
}

.detailsSectionContainer {
  max-height: 500px;
  overflow: auto;
  position: relative;
}

.sectionGridContainer {
  .sectionGrid:first-child {
    position: sticky;
    top: 1rem;
    background: white;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }
}

.sectionGrid {
  display: grid;
  grid-template-columns: 1.75fr 1fr 1.25fr 0.6fr 0.8fr;
  gap: 2rem;
  margin-bottom: 1rem;
  font-family: helveticaneue;
}

.detailSection {
  margin-bottom: 3rem;
}

.columnHeadline {
  font-size: 1rem;
  color: rgba(17, 2, 2, 0.6);
}

.actions {
}

.pagination {
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: space-around;
}

.actions {
  padding: 0 4rem 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.paginationButton {
}

.paginationDivider {
  color: $primary;
  font-weight: 600;
}
</style>